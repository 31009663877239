.taskListItem {
    margin: 15px;
    color: white;
    background-color: rgb(5 63 94);
    border-radius: 1.25rem!important;
}
.taskListItem:hover{
    cursor: pointer;
}

.taskListItemSubscriptionInfo {
    text-align: center;
    font-size: 10px;
}

.taskListItemDescriptionInfo>p {
    color: #b4bcc3!important;
}

.taskListItemDescriptionInfo {
    font-size: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 12px;
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .taskListItemSubscriptionInfo-pad {
        position: absolute;
        bottom: 0;
        right: 0;
    }
}

.itemMain {
    border-color: #000;
    background-color: white;
    border: solid;
    border-width: thin;
    height: 50px;
}

.itemNew {
    border-color: #000;
    background-color: white;
    border: dotted;
    height: 50px;
}

.itemImg {
    width: 24px;
    height: 24px;
    margin-right: 20px;
}

.itemTitle {
    font-size: 24px;
    font-weight: bold;
}

.scheduleTitle {
    font-size: 19px;
    font-weight: bold;
}

.itemNewTitle {
    font-size: 24px;
}

.itemRightArrow {
    color: #000;
    float: right;
    font-size: 24px;
    font-weight: bold;
}

.trashItem {
    margin-top: 200px;
}

.actionIcons {
    display: inline-flex;
    margin: 0 10px;
}

.actionIcon {
    margin-right: 7px;
}

@media (min-width: 300px) and (max-width: 500.98px) {
    .actionIcons {
        display: inline-flex;
        margin: 0 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .trashItem {
        margin-top: 250px;
    }
}

@media (max-width: 767.98px) {
    .itemRightArrow {
        display: none;
    }
    .itemTitle {
        font-size: 15px;
    }
    .scheduleTitle {
        font-size: 14px;
    }
}
.ReactTags__tags {
    position: relative;
  }
  
  .ReactTags__clearAll {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    background: #f88d8d;
    color: #fff;
    border: none;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    border-radius: 2px;
    margin-bottom: 5px;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    border: 1px solid #eee;
    min-width: 150px;
  }
  
  .ReactTags__editInput {
    border-radius: 1px;
  }
  
  .ReactTags__editTagInput {
    display: inline-flex;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }
  .miniBotTagger .ReactTags__tags{
    margin-bottom: 30px;
    width: 65%;
  }
  