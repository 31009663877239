@font-face {
    font-family: 'ABCArizonaMix-Light';
    src:  url(../assets/ABCArizonaMix-Light.otf);
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.mainTitle {
    font-family: 'ABCArizonaMix-Light';
    font-style: normal;
    max-width: 20ch;
    margin-left: auto;
    margin-right: auto;
    font-size: 52px;
}
@media (min-width: 300px) and (max-width: 767.98px) {
    .mainTitle {
        font-size: 2.5rem;
    }
}


