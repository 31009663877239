.bg-bot-yellow {
    background-color: #fffdf9!important;
    border-bottom: 1px solid #eaeae4;
}

.navbar-brand {
    color: black !important;
}

.navbar-brand img {
    width: 90px;
    height: 50px;
}
.navbar-light .navbar-nav .nav-link{
    color: black;
    font-family: Inter,sans-serif;
}