.templateListItem {
    margin: 15px;
    color: white;
    background-color: #007bff;
    border-radius: 1.25rem !important;
}

.template-icon {
    margin-top: 20px;
}

.templateListItemDescriptionInfo>p {
    color: white !important;
    font-size: 25px;
}

.template-title {
    font-size: 39px;
    color: white;
    margin-top: 10px;
}

@media (max-width:1050.98px) {
    .template-title {
        font-size: 29px;
    }

    .template-icon {
        width: 52px;
        height: 52px;
    }
}

@media (max-width: 800.98px) {
    .headerText {
        font-size: 45px !important;
        font-weight: 400;
        color: #000;
        margin-top: 80px;
        text-align: center;
    }

    .template-title {
        font-size: 29px;
    }

    .template-icon {
        width: 52px;
        height: 52px;
    }
}

.cover-container {
    width: 100%;
    white-space: nowrap;
  
}

.cover-item {
    width: 440px;
    padding: 40px;
  
}

.cover-custom {
    border-radius: 20px;
    background: var(--brand-light-teal, #CDE4E1);
}


.styles_sliderWrapper__KX5Uw {
    position: relative;
    width: 100vw;
}

.max-w-full {
    max-width: 100%;
}

.styles_cardsContainer__ocwLl {
    width: -moz-max-content;
    width: max-content;
}
.col-item{
    margin: 10px 2px;
}

.styles_defaultCardBackground__U7NNW {
    background: #cde4e1;
}

.styles_cardContainer__v2_eP {
    position: relative;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 3rem;
    background-size: cover;
    background-position: 50%;
    padding: 3.3rem 2.8rem;
    width: 341.6px;
    height: 357.6px;
    transition: transform .3s ease-in-out;
}

.styles_plusIcon__Nggcw {
    width: 8rem;
    height: 8rem;
}
.styles_defaultCardLabel__kXiom {
    color: #1f1e1b;
}

@media (width < 576px) {
    .styles_cardContainer__v2_eP {
        width: 160px;
        height: 180px;
    }
}

.styles_cardLabel__SqmcF {
    z-index: 1;
    width: -moz-min-content;
    width: min-content;
    width: 100%;
    font-family: Haas Grot Disp Medium, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1.435px;
    letter-spacing: 0;
}
@media (width < 576px){
.styles_cardLabel__SqmcF {
   font-size: 21px;
}
.col-item {
   padding-right: 0px;
 }
}
@media (min-width: 768px) and (max-width: 991.98px) { 
    .col-item {
        padding-right: 0px;
        padding-left: 0px;
      }
 }
.styles_cardLabelColor__DNbYJ {
    color: #fff;
}

.styles_cardContainer__v2_eP>img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    filter: brightness(.7);
    transition: filter .3s ease-in-out;
}
.styles_cardContainer__v2_eP:hover {
    transform: translateY(-2rem);
}
.templateItems .col {flex-grow: 0!important;}