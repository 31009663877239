.pricebox {
    border-radius: 1.25rem!important;
    margin: 10px;
}

.p1 {
    background-color: rgb(255 231 4);
}

.p2 {
    background-color: rgb(253 222 111);
}

.p3 {
    background-color: rgb(253 243 0);
}

.pricerow {
    margin-bottom: 30px;
}

.typeCol {
    padding-top: 35pt;
}

.subscriptionType {
    text-decoration: underline;
    color: black;
    font-weight: 600;
    padding-left: 37px;
}

.subscriptionPrice {
    color: black;
    font-weight: 700;
    padding-left: 20pt;
}

.detailRow {
    margin-bottom: 10pt;
    font-size: larger;
    font-weight: 400;
}

.greencheck {
    padding-left: 45px!important;
    padding-right: 10pt!important;
}

.trueg1 {
    background-color: #17b850!important;
    color: white;
}

.priceCol {
    padding-top: 35pt;
}

.freetrial {
    padding-left: 22pt
}

.subscriptionPlusDialog>.modal-dialog {
    max-width: 40%;
}

@media (max-width: 575.98px) {
    .pricebox {
        width: 65%;
    }

    .subscriptionType {
        padding-left: 0pt;
        font-size: 1.3em;
    }

    .subscriptionType>.badge {
        font-size: 66%;
    }

    .subscriptionPrice {
        padding-left: 0pt;
        font-size: 0.9em;
    }

    .freetrial {
        padding-left: 0
    }

    .subscriptionPlusDialog>.modal-dialog {
        max-width: 40%;
    }
}

@media (max-width: 576px) {
    .subscriptionPlusDialog>.modal-dialog {
        max-width: 100%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .pricebox {
        width: 65%;
    }

    .subscriptionType {
        padding-left: 0pt;
    }

    .subscriptionPrice {
        padding-left: 0pt;
    }

    .detailRow {
        font-size: small;
    }

    .freetrial {
        padding-left: 0
    }

    .subscriptionPlusDialog>.modal-dialog {
        max-width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1010.98px) {
    .subscriptionPlusDialog>.modal-dialog {
        max-width: 60%;
    }
}

@media (min-width: 1011px) and (max-width: 1400.98px) {
    .subscriptionPlusDialog>.modal-dialog {
        max-width: 50%;
    }
}


@media only screen and (max-width: 767px) {
    .detailRow {
        font-size: small;
    }
}

.summarydetails {
    margin: 15px 12px;
}